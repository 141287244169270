import React, { useRef, useState, useCallback, useContext, useEffect } from 'react';
import type { FC } from 'react';
import { styled } from '@compiled/react';

import { N80 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import type { StylesConfig } from '@atlaskit/select';

import { LazyPageLabelsAutomationDiscovery } from '@confluence/automation-discovery/entry-points';
import { keys, confluenceLocalStorageInstance as localStorage } from '@confluence/storage-manager';
import {
	VIEW_PAGE_LABELS_EXPERIENCE,
	ExperienceStart,
	ExperienceTrackerContext,
	AUTOMATION_DISCOVERY_ADD_LABEL_EXPERIENCE,
} from '@confluence/experience-tracker';
import { ContentAppearanceType } from '@confluence/content-appearance';
import { Attribution, withErrorBoundary } from '@confluence/error-boundary';
import { GeneralShortcutListener, LABELS_SHORTCUT } from '@confluence/shortcuts';
import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition';

import type { CustomOptionType } from './LabelsSelect';
import { LabelsSelect } from './LabelsSelect';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

type ViewPageLabelsContainerProps = {
	isDefaultWidth: boolean;
};
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ViewPageLabelsContainer = styled.div<ViewPageLabelsContainerProps>({
	margin: `${token('space.1000', '80px')} auto ${token('space.500', '40px')} auto`,
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: (props) => (props.isDefaultWidth ? '760px' : 'unset'),
});

const viewPageStyles: StylesConfig<CustomOptionType, true> = {
	container: (provided) => ({
		...provided,
		margin: `0 -10px ${token('space.200', '16px')}`,
	}),
	control: (provided) => ({
		...provided,
		backgroundColor: token('elevation.surface', 'white'),
		'&:hover, &:focus, &:active, &:focus-within': {
			backgroundColor: token('elevation.surface', 'white'),
			borderColor: token('color.border', N80),
		},
		':not(&:focus-within, :hover)': {
			borderColor: 'transparent',
		},
	}),
	menu: (provided) => ({
		...provided,
		zIndex: 5,
	}),
};

type ViewPageLabelsComponentProps = {
	pageWidthType: string;
	contentId: string;
	spaceKey: string | undefined;
	spaceId: string | undefined | null;
	contentSubType?: string | null;
};

export const ViewPageLabelsComponent: FC<ViewPageLabelsComponentProps> = ({
	pageWidthType,
	contentId,
	spaceKey,
}) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const [shouldDisplayAutomationDiscovery, setShouldDisplayAutomationDiscovery] = useState(false);
	const labelsRef = useRef<HTMLDivElement>(null);

	const experienceTracker = useContext(ExperienceTrackerContext);
	const { edition } = useSessionData();

	useEffect(() => {
		// unmount LazyPageLabelsAutomationDiscovery if the user navigates to another page
		if (shouldDisplayAutomationDiscovery) {
			setShouldDisplayAutomationDiscovery(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentId]);

	const checkShouldDisplayAutomationDiscovery = useCallback(() => {
		// Automation is a premium feature
		if (edition === ConfluenceEdition.PREMIUM) {
			const hasDismissedDiscoveryMessage = localStorage.getItemAsBoolean(
				keys.AUTOMATION_DISCOVER_ADD_LABEL_TO_PAGE,
			);

			// Don't render the experience if they've already seen and dismissed it
			if (!hasDismissedDiscoveryMessage) {
				setShouldDisplayAutomationDiscovery(true);
				experienceTracker.start({
					name: AUTOMATION_DISCOVERY_ADD_LABEL_EXPERIENCE,
				});
			}
		}
	}, [edition, experienceTracker]);

	const hideDiscoveryMessage = () => {
		setShouldDisplayAutomationDiscovery(false);
	};

	const openLabels = (e) => {
		// prevents text from being entered when pressing shortcut key
		e.preventDefault();
		labelsRef.current?.focus();
	};

	return (
		<>
			<ExperienceStart id={contentId} name={VIEW_PAGE_LABELS_EXPERIENCE} />
			<ViewPageLabelsContainer
				isDefaultWidth={JSON.parse(pageWidthType) === ContentAppearanceType.DEFAULT}
				data-testid="view-page-labels-container"
				data-vc="view-page-labels-container"
				{...ssrPlaceholderIdProp}
			>
				<GeneralShortcutListener accelerator={LABELS_SHORTCUT} listener={openLabels} />
				<LabelsSelect
					key={`labels-select-${contentId}`}
					source="viewPage"
					experienceName={VIEW_PAGE_LABELS_EXPERIENCE}
					contentId={contentId}
					spaceKey={spaceKey}
					styles={viewPageStyles}
					ref={labelsRef}
					maxMenuHeight={208}
					//@ts-ignore react-select types
					onAdded={checkShouldDisplayAutomationDiscovery}
				/>
				{shouldDisplayAutomationDiscovery && (
					<LazyPageLabelsAutomationDiscovery
						spaceKey={spaceKey}
						onDismiss={hideDiscoveryMessage}
						onProceed={hideDiscoveryMessage}
					/>
				)}
			</ViewPageLabelsContainer>
		</>
	);
};

export const ViewPageLabels = withErrorBoundary({
	attribution: Attribution.DISCO,
})(ViewPageLabelsComponent);
